<template>
  <div class="d-none d-md-none d-lg-flex top-header px-5 py-2 ">
    <div class="me-auto">
      <b-link href="#" class="mx-2" >
        <font-awesome-icon :icon="['fab', 'twitter']" />
      </b-link>
      <b-link href="#" class="mx-2" >
        <font-awesome-icon :icon="['fab', 'facebook']" />
      </b-link>
      <b-link href="#" class="mx-2" >
        <font-awesome-icon :icon="['fab', 'google']" />
      </b-link>
      <b-link href="#" class="mx-2" >
        <font-awesome-icon :icon="['fab', 'pinterest']" />
      </b-link>
    </div>
    <div >
      <font-awesome-icon icon="phone" class="text-white mx-3"/>
      <span class="text-white mx-3">+57 315 246 9739</span>
      <font-awesome-icon icon="clock" class="text-white mx-3"/>
      <span class="text-white mx-3">Lun-Vie: 8:00 - 17:00, Sab: 8:00 - 12:00.</span>
        <button type="button" class="btn btn-success mx-3">Solicitud de servicio</button>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style>
</style>

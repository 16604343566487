<template>
  <div class="clearfix">
    <div class="page-header">
        <header class="header-bg">
          <Topbar />
          <Navbar />
        </header>
      <Breadcrums />
     </div>
    <router-view />
    <Footer />
    <Legal />
  </div>
</template>
<script>
import '@/assets/style.css'
import Topbar from './Topbar'
import Navbar from './Navbar'
import Breadcrums from './Breadcrums'
import Footer from './Footer'
import Legal from './Legal'

export default {
  name: 'Base',

  components: {
    Topbar,
    Navbar,
    Breadcrums,
    Footer,
    Legal
  },
  mounted () {

  }
}
</script>

<style>
</style>

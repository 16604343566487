<template>
  <div class="d-flex  bottom px-5">
    <div class="me-auto copyright">
      <span>HECHO CON</span> <font-awesome-icon icon="heart" class="text-danger mx-1"/>
      <span>POR SETEG SAS | &copy; 2021</span>
    </div>
    <div class="">
       <ul class="bottom-nav">
        <li class="bottom-nav-item">
          <a class="bottom-nav-link" href="/">INICIO</a>
        </li>
        <li class="bottom-nav-item">
          <a class="bottom-nav-link" href="/empresa">EMPRESA</a>
        </li>
        <li class="bottom-nav-item">
          <a class="bottom-nav-link" href="/contactenos">CONTACTENOS</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style>
</style>

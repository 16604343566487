<template>
  <div class="d-flex mx-5">
    <div class="me-auto p-2 ">
      <h3 class="text-white">{{ paginaActual }}</h3>
    </div>
    <div class="p-2">
      <ol class="breadcrumb my-0">
        <li class="breadcrumb-item">
          <a href="/" >Medicert Ltda.</a>
        </li>
        <li class="breadcrumb-item active">
          <a href="#">{{ paginaActual }}</a>
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    paginaActual () {
      return this.$store.state.actualPage
    }
  }
}
</script>

<style>
</style>

<template>
  <footer class="d-flex">
    <div class="container">
      <div class="row my-4">
        <div class="col-4">
          <h4>HORARIO DE ATENCIÓN</h4>
          <div class="linea"></div>
          <p>
            También puede solicitar información a través de nuestro formulario
            de contacto.
          </p>
          <p>Atendemos de:</p>
          <p>
            Lunes – Viernes: 08.00 – 17.00
            <br />
            Sábados: 8.00 – 12.00
            <br />
            Domingo: Cerrado
          </p>
        </div>
        <div class="col-4">
          <h4>NUESTRAS OFICINAS</h4>
          <div class="linea"></div>
          <p>Dirección: Carrera 73C Bis # 38A – 35 Sur, Oficina 101 -Bogotá</p>
          <p>Celular: +57 315 246 9739</p>
          <p>Teléfono:(+571) 805-35-77 Bogotá D.C.</p>
          <p>E-mail: comercial@medicert.com.co</p>
        </div>
        <div class="col-4">
          <h4>CERTIFICADOS EN LÍNEA</h4>
          <div class="linea"></div>
          <img src="/img/logo.jpg" width="300px" class="mb-5" />
          <p>
            Puede consultar en línea nuestros certificados expedidos en
            cualquier momento.
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Fotter'
}
</script>

<style>
</style>

<template>
  <b-navbar toggleable="lg" class="px-5">
    <!-- logo -->
    <b-navbar-brand  href="#">
      <img src="/img/logo.jpg" width="300" class="ms-5">
    </b-navbar-brand>
    <!-- Toggler -->
    <b-navbar-toggle target="nav-collapse">
      <template >
        <font-awesome-icon icon="bars" class="text-white"/>
      </template>
    </b-navbar-toggle>
    <!-- Right aligned nav items -->
    <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ms-auto">
          <b-nav-item href="/" class="active">INICIO</b-nav-item>
          <b-nav-item href="/empresa">EMPRESA</b-nav-item>
          <b-nav-item href="/certificados">CONSULTAR CERTIFICADO</b-nav-item>
          <b-nav-item href="/portafolio">PORTAFOLIO</b-nav-item>
          <b-nav-item href="/quejas">QUEJAS Y APELACIONES</b-nav-item>
          <b-nav-item href="/documentos">DOCUMENTOS DE CONSULTA</b-nav-item>
          <b-nav-item href="/contactenos">CONTÁCTENOS</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
  </b-navbar>
</template>

<script>
export default {}
</script>

<style>
</style>
